
import { Options, Vue } from 'vue-class-component';
import { clientModule } from '@/modules/client/client.vuex-module';
import { ClientLocation } from '@/common/models/ClientLocation.model';
import { Prop } from 'vue-property-decorator';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';

@Options({
	name: 'OrderView',
	components: {},
})
export default class PortalView extends Vue {
	@Prop({ default: '0' }) orderKey!: string;
	@Prop({ default: '0' }) clientLocationKey!: string;
	get order(): ActiveOrder | null {
		return orderModule._activeOrder;
	}
	get logourl(): string | null {
		if (!this.location) {
			return null;
		}
		if (!this.location.PhotoDomain || !this.location.PhotoFolder || !this.location.LightLogoVersion) {
			return null;
		}
		return this.location.PhotoDomain + 'h_140' + '/v' + this.location.LightLogoVersion + this.location.PhotoFolder + '/darklogo';
	}
	get location(): ClientLocation | null {
		return clientModule.location;
	}
	get loading(): boolean {
		return clientModule.isLoading;
	}
	created(): void {
		clientModule.updateClientLocation(parseInt(this.clientLocationKey)).then(() => {
			orderModule.getOrder(this.orderKey);
		});
	}
	mounted(): void {
		// this.$router.push({ path: 'view' });
	}
}
